import WebServiceRequest from './WebServiceRequest'

class BlockchainCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Blockchain/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class BlockchainUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Blockchain/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class BlockchainTrasferBetweenInternalTatumAccountRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Blockchain/TrasferBetweenInternalTatumAccount')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class BlockchainGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Blockchain/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class BlockchainGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Blockchain/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    BlockchainCreateRequest,
    BlockchainUpdateRequest,
    BlockchainGetRequest,
    BlockchainGetAllRequest,
    BlockchainTrasferBetweenInternalTatumAccountRequest
};
